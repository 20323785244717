import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import "../css/typography.css"
import useWindowSize from "../hooks/useWindowSize"
import Cibeles from "../images/inversionista/Cibeles.webp"
import TitleBackground from "../images/home/TitleBackground.webp"
import TitleBackMov from "../images/home/TitleBackMov.webp"
import Compra from "../images/inversionista/Compra.webp"
import Entrevista from "../images/inversionista/Entrevista.webp"
import España from "../images/inversionista/España.webp"
import Firma from "../images/inversionista/Firma.webp"
import Pago from "../images/inversionista/Pago.webp"
import Proceso from "../images/inversionista/Proceso.webp"
import Recopilación from "../images/inversionista/Recopilación.webp"
import Tarjeta from "../images/inversionista/Tarjeta.webp"
import Visita from "../images/inversionista/Visita.webp"
import Workflow from "../images/inversionista/Workflow.webp"
import WorkflowMov from "../images/inversionista/WorkflowMov.webp"
import Dots from "../images/home/Dots.webp"
import Check from "../images/inversionista/Check.webp"
import CTA from "../components/buttons/cta"
import { Helmet } from 'react-helmet'

const ContactoDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
`

const FirstSection = styled.div`
    background-image: url(${Cibeles});
    background-size: cover;
    margin: 0;
    color: white;
    min-height: 50vh;
    display: flex;
    align-items: center;

    >div:nth-child(1) {
        width: 900px;
        background-image: url(${TitleBackground});
        background-size: cover;
        font-size: 15.5px;
        font-family: 'M Extra Bold';
        padding: .1% 0 .1% 3%;
    }

    @media only screen and (min-width: 1800px) {
        >div:nth-child(1) {
            width: 1250px;
            font-size: 30px;
        }
    }
    @media only screen and (min-width: 2500px) {
        >div:nth-child(1) {
            width: 1450px;
            font-size: 40px;
        }
    }
    @media only screen and (min-width: 3500px) {
        >div:nth-child(1) {
            width: 1850px;
            font-size: 50px;
        }
    }
    @media only screen and (min-width: 4000px) {
        >div:nth-child(1) {
            width: 2100px;
            font-size: 58px;
        }
    }
    @media only screen and (max-width: 885px) {
        >div:nth-child(1) {
            width: 750px;
            font-size: 14.5px;
            padding: .1% 0 .1% 3%;
        }
    }
    @media only screen and (max-width: 736px) {
        >div:nth-child(1) {
            width: 580px;
            font-size: 11px;
            padding: .1% 0 .1% 3%;
        }
    }
    @media only screen and (max-width: 570px) {
        width: 100vw;
        min-height: 40vh;

        >div:nth-child(1) {
            width: 99%;
            font-size: 3vw;
            padding: .1% 0 .1% 3%;
            background-image: url(${TitleBackMov});
            background-position-x: 100%;
        }
        >div:nth-child(1) h1 {
            width: 92%;
        }
    }
`

const SecondSection = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 70px;
    position: relative;

    >div:nth-child(1) {
        display: flex;
        flex-direction: column;
        margin-right: 70px;
    }
    >div:nth-child(1) img {
        width: 70px;
    }
    >div:nth-child(2) img {
        width: 58px;
    }
    >div:nth-child(2) {
        margin-bottom: 20px;
    }
    >div:nth-child(3) {
        font-family: 'M Medium';
        display: flex;
        flex-direction: column;
        font-size: 17px;
        margin-left: 70px;
    }
    p {
        width: 380px;
    }
    >div:nth-child(3)>div:nth-child(7) p {
        font-family: 'M Bold';
        color: #7C2331;
        width: 450px;
    }
    >div:nth-child(3) div{
        height: 155px;
        display: flex;
        align-items: flex-start;
        margin: 0;
    }
    >div:nth-child(3) div p {
        margin-top: 5px;
    }
    >div:nth-child(3)>div:nth-child(5) p {
        margin-top: 7px;
    }
    >div:nth-child(3)>div:nth-child(6) p {
        margin-top: 0;
    }
    >div:nth-child(3)>div:nth-child(6) {
        height: 120px;
    }
    >div:nth-child(3)>div:nth-child(7) {
        height: 85px;
    }
    >div:nth-child(3)>div:nth-child(9) p {
        height: 85px;
        margin-top: -1px;
    }
    >div:nth-child(3)>div:nth-child(10) p {
        height: 85px;
        margin-top: -5px;
    }
    >div:nth-child(1) div {
        height: 154px;
        display: flex;
        align-items: flex-start;
        margin: 0;
    }
    >div:nth-child(1)>div:nth-child(7) {
        height: 200px;
        align-items: center;
    }
    >div:nth-child(1)>div:nth-child(7) img {
        margin-top: -35px;
    }
    .right-top, .right-bottom, .left-top, .left-middle, .left-bottom {
        position: absolute;
        width: 100px;
    }
    .left-top {
        top: 10px;
        left: 10px;
    } 
    .left-middle {
        top: 45%;
        left: 10px;
    } 
    .left-bottom {
        top: 90%;
        left: 10px;
    } 
    .right-top {
        right: 5px;
        top: 25%
    }
    .right-bottom {
        right: 5px;
        bottom: 25%
    }

    @media only screen and (min-width: 1800px) {
        margin-bottom:100px;

        >div:nth-child(1) div {
        height: 252px; 
        }
        >div:nth-child(1) img {
        width: 110px;
        }
        >div:nth-child(1)>div:nth-child(7) img {
        margin-top: 70px;
        }
        >div:nth-child(1)>div:nth-child(8) img {
        margin-top: 130px;
        }
        >div:nth-child(1)>div:nth-child(9) img {
        margin-top: 130px;
        }
        >div:nth-child(2) img {
        width: 95px;
        }
        >div:nth-child(3) div {
        height: 250px;
        }
        >div:nth-child(3) p {
        font-size: 35px;
        width: 800px;
        margin-top: 50px;
        }
        >div:nth-child(3)>div:nth-child(5) p {
        margin-top: 40px;
        }
        >div:nth-child(3)>div:nth-child(7) p {
        margin-top: 100px;
        width: 800px;
        }
        >div:nth-child(3)>div:nth-child(8) p {
        margin-top: 170px;
        }
        >div:nth-child(3)>div:nth-child(9) p {
        margin-top: 150px;
        }
        >div:nth-child(3)>div:nth-child(10) p {
        margin-top: 150px;
        }
    }
    @media only screen and (min-width: 3500px) {
        margin-bottom:200px;

        >div:nth-child(1) div {
        height: 490px; 
        }
        >div:nth-child(1) img {
        width: 220px;
        }
        >div:nth-child(1)>div:nth-child(7) img {
        margin-top: 320px;
        }
        >div:nth-child(1)>div:nth-child(8) img {
        margin-top: 450px;
        }
        >div:nth-child(1)>div:nth-child(9) img {
        margin-top: 450px;
        }
        >div:nth-child(2) img {
        width: 185px;
        }
        >div:nth-child(3) div {
        height: 490px;
        }
        >div:nth-child(3) p {
        font-size: 65px;
        width: 1500px;
        margin-top: 50px;
        }
        >div:nth-child(3)>div:nth-child(5) p {
        margin-top: 40px;
        }
        >div:nth-child(3)>div:nth-child(7) p {
        margin-top: 280px;
        width: 1600px;
        }
        >div:nth-child(3)>div:nth-child(8) p {
        margin-top: 470px;
        }
        >div:nth-child(3)>div:nth-child(9) p {
        margin-top: 450px;
        }
        >div:nth-child(3)>div:nth-child(10) p {
        margin-top: 450px;
        }
    }
    @media only screen and (max-width: 960px) {
        .left-bottom, .left-top, .left-middle {
            display: none;
        }
    }
`

const SecondSectionMov = styled.div`
    display: flex;
    position: relative;
    padding: 10% 3% 40% 2%;
    overflow: hidden;

    >div:nth-child(1) img {
        width: 10vw;
    }
    >div:nth-child(2) img {
        width: 13vw;
    }
    >div:nth-child(2) div {
        font-family: 'M Medium';
        font-size: 4vw;
        margin-left: 5%;
    }
    >div:nth-child(2) div p {
        margin-top: 2%;
    }
    >div:nth-child(2)>div:nth-child(1) {
        height: 10.2%;
    }
    >div:nth-child(2)>div:nth-child(2) {
        height: 11.5%;
    }
    >div:nth-child(2)>div:nth-child(3) {
        height: 10.7%;
    }
    >div:nth-child(2)>div:nth-child(4) {
        height: 14.9%;
    }
    >div:nth-child(2)>div:nth-child(5) {
        height: 9.6%;
    }
    >div:nth-child(2)>div:nth-child(6) {
        height: 11.2%;
    }
    >div:nth-child(2)>div:nth-child(7) {
        height: 5.2%;
        font-family: 'M Bold';
        color: #7C2331;
    }
    >div:nth-child(2)>div:nth-child(8) {
        height: 9.9%;
    }
    >div:nth-child(2)>div:nth-child(9) {
        height: 12%;
    }
    .right-top, .left-middle, .right-middle, .left-bottom {
        width: 14%;
        position: absolute;
    }
    .right-top {
        right: 3%;
        top: 1%;
    }
    .left-middle {
        top: 40%;
        left: -9%;
    }
    .right-middle {
        bottom: 26%;
        right: -2%;
    }
    .left-bottom {
        bottom: .5%;
        left: 1%;
    }
`

const LastSection = styled.div`
    background-color: #0B2A3B;
    opacity: 91%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3%;

    h2 {
        margin-top: 13%;
        font-family: 'M Regular';
        font-size: 23px;
        width: 350px;
        color: white;
        text-align: center;
    }
    span {
        font-family: 'M Extra Bold';
    }
    >div:nth-child(1) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 4%;
    }
    >div:nth-child(2), >div:nth-child(3) {
        display: flex;
        width: 100vw;
        padding: 0 3% 0 3%;
        justify-content: center;
        margin-bottom: 50px;
    }
    @media only screen and (min-width: 1200px) {
        >div:nth-child(1) h2 {
        font-size: 25px;
        width: 380px;
        }
    }
    @media screen and (min-width: 1800px) {
        >div:nth-child(1) h2 {
        font-size: 40px;
        width: 600px
        }
    }
    @media screen and (min-width: 2500px) {
        >div:nth-child(1) h2 {
        font-size: 60px;
        width: 900px
        }
    }
    @media screen and (min-width: 3500px) {
        >div:nth-child(1) h2 {
        font-size: 68px;
        width: 1000px
        }
    }
    @media screen and (min-width: 4000px) {
        >div:nth-child(1) h2 {
        font-size: 82px;
        width: 1100px
        }
    }
    @media screen and (max-width: 1020px) {
        >div:nth-child(2) {
            width: 100vw;
            display: flex;
            flex-direction: column;
            justify-content:center;
            align-items: center;
        }
    }
`

const SeparatorLight = styled.div`
    width: 50px;
    border-bottom: 3px solid white;
    border-radius: 50px;
    @media only screen and (min-width: 1200px) {
        
        width: 80px;  
    }
    @media only screen and (min-width: 1800px) {
        
        width: 120px;
    }
    @media only screen and (min-width: 2500px) {
        
        width: 200px;
    }
    @media only screen and (min-width: 3500px) {
        
        width: 250px;
    }
    @media only screen and (max-width: 750px) {
        margin-bottom: 10%;
    }
` 

const CardRight = styled.div`
    padding: 5px 5px 5px 50px;
    width: 400px;
    height: 80px;
    box-shadow: -10px 10px 0 3px #7d8896;
    background-color: white;
    position: relative;
    font-family: 'M Medium';
    font-size: 17px;
    display: flex;
    align-items: center;

    img {
        width: 50px;
        position: absolute;
        top: 25%;
        right: -6%;
    }

    p {
        width: 90%;
    }
    @media screen and (min-width: 1200px) {
       
       p {
           font-size: 20px;
           width: 350px;
       }
       img{
           width: 50px;
       }
        
    }
    @media screen and (min-width: 1800px) {

        width: 600px;
        height: 130px;

        p {
           font-size: 30px;
           width: 550px;
       }
       img{
           width: 70px;
           
       }
    }
    @media screen and (min-width: 2500px) {

        width: 800px;
        height: 180px;

        p {
           font-size: 40px;
           width: 700px;
       }
       img{
           width: 90px;
           
       }
    }
    @media screen and (min-width: 3500px) {
        
        width: 1000px;
        height: 220px;

        p {
           font-size: 50px;
           width: 890px;
       }
       img{
           width: 90px;
           
       }
    }
    @media screen and (min-width: 4000px) {
               
        width: 1300px;
        height: 320px;

        p {
           font-size: 70px;
           width: 1290px;
       }
       img{
           width: 150px;
           
       }
    }
    @media screen and (max-width: 1020px) {
        margin-bottom: 40px;
    }
    @media screen and (max-width: 520px) {
        padding: 4% 4% 4% 10%;
        width: 70%;
        font-size: 4.5vw;  
    }
`

const CardLeft = styled.div`
    padding: 5px 5px 5px 50px;
    width: 400px;
    height: 80px;
    box-shadow: 10px 10px 0 3px #7d8896;
    background-color: white;
    position: relative;
    font-family: 'M Medium';
    font-size: 17px;
    display: flex;
    align-items: center;
    margin-right: 4%;

    img {
        width: 50px;
        position: absolute;
        top: 25%;
        left: -5%;
    }
    @media screen and (min-width: 1200px) {
       
       p {
           font-size: 20px;
           width: 350px;
       }
       img{
           width: 50px;
           heigth: 80px;
       }
        
    }
    @media screen and (min-width: 1800px) {

    width: 600px;
    height: 130px;

        p {
            font-size: 30px;
            width: 550px;
        }
        img {
            width: 70px; 
        }
    }
    @media screen and (min-width: 2500px) {

    width: 800px;
    height: 180px;

        p {
            font-size: 40px;
            width: 700px;
            margin-left: 20px;
        }
        img{
            width: 90px;
        }
    }
    @media screen and (min-width: 3500px) {
        
        width: 1000px;
        height: 220px;

        p {
           font-size: 50px;
           width: 890px;
       }
       img{
           width: 90px;
           
       }
    }
    @media screen and (min-width: 4000px) {
               
               width: 1300px;
               height: 320px;
       
               p {
                  font-size: 70px;
                  width: 1290px;
                  margin-left: 70px;
              }
              img{
                  width: 150px;
                  
              }
           }
    @media screen and (max-width: 520px) {
        padding: 4% 4% 4% 10%;
        width: 70%;
        font-size: 4.5vw;  
    }
`

const IndexPage = () => {
    const windowSize = useWindowSize();
    const [hasMounted, setHasMounted] = useState(false) 

    useEffect(() => {
        setHasMounted(true)
    }, [])

    return hasMounted ? (
        <Layout>
        <Helmet>
            <title>Cómo invertir en bienes raíces en España desde México</title>
            <meta name="description" content="Descubre cómo invertir en bienes raíces en España. Desde la búsqueda del inmueble hasta la obtención de la Golden Visa."/>
        </Helmet>
        <FirstSection title="Conoce todo el proceso para invertir en bienes raíces en España.">
            <div>
                <h1>CRONOGRAMA DE LA INVERSIÓN</h1>
            </div>
        </FirstSection>
        {windowSize > 730 ? (
            <SecondSection>
            <div>
                <div>
                    <img src={Entrevista} alt="Entrevista" />
                </div>
                <div>
                    <img src={Firma} alt="Firma" />
                </div>
                <div>
                    <img src={Proceso} alt="Proceso" />
                </div>
                <div>
                    <img src={Visita} alt="Visita" />
                </div>
                <div>
                    <img src={Compra} alt="Compra" />
                </div>
                <div>
                    <img src={Pago} alt="Pago" />
                </div>
                <div>
                    <img src={Recopilación} alt="Recopilación" />
                </div>
                <div>
                    <img src={España} alt="España" />
                </div>
                <div>
                    <img src={Tarjeta} alt="Tarjeta" />
                </div>
            </div>
                <div>
                    <img src={Workflow} alt="workflow" />
                </div>
                <div>
                    <div>
                        <p>Entrevista inicial para conocer todas las necesidades personales de inversión.</p>
                    </div>
                    <div>
                        <p>Firma de contrato de mandato de inversión entre Ingezar y el inversionista.</p>
                    </div>
                    <div>
                        <p>Proceso de búsqueda y preselección de propiedades.</p>
                    </div>
                    <div>
                        <p>Visita del inversionista a España para elegir la propiedad. Entrevista con el equipo legal. Apertura de cuenta de banco. Nombramiento de un apoderado legal.</p>
                    </div>
                    <div>
                        <p>Compra de la propiedad.</p>
                    </div>
                    <div>
                        <p>Pago de impuestos y registro de la propiedad, ambos pueden ser realizados por el apoderado legal.</p>
                    </div>
                    <div>
                        <p>EN CASO DE INTERÉS EN LA GOLDEN VISA:</p>
                    </div>
                    <div>
                        <p>Recopilación de documentos.</p>
                    </div>
                    <div>
                        <p>Visita a España para iniciar el proceso de aplicación al permiso de residencia.</p>
                    </div>
                    <div>
                        <p>Visita a España para recibir la tarjeta de residencia (20 días hábiles después de la aplicación).</p>
                    </div>
                </div>
                <img src={Dots} alt="Dots" className="left-top" />
                <img src={Dots} alt="Dots" className="left-middle" />
                <img src={Dots} alt="Dots" className="left-bottom" />
                <img src={Dots} alt="Dots" className="right-top" />
                <img src={Dots} alt="Dots" className="right-bottom" />
            </SecondSection>
        ) : (
            <SecondSectionMov>
                <div>
                    <img src={WorkflowMov} alt="Workflow" />
                </div>
                <div>
                    <div>
                        <img src={Entrevista} alt="Enrevista" />
                        <p>Entrevista inicial para conocer todas las necesidades personales de inversión.</p>
                    </div>
                    <div>
                        <img src={Firma} alt="Firma" />
                        <p>Firma de contrato de mandato de inversión entre Ingezar y el inversionista.</p>
                    </div>
                    <div>
                        <img src={Proceso} alt="Proceso" />
                        <p>Proceso de búsqueda y preselección de propiedades.</p>
                    </div>
                    <div>
                        <img src={Visita} alt="Visita" />
                        <p>Visita del inversionista a España para elegir la propiedad. Entrevista con el equipo legal. Apertura de cuenta de banco. Nombramiento de un apoderado legal.</p>
                    </div>
                    <div>
                        <img src={Compra} alt="Compra" />
                        <p>Compra de la propiedad.</p>
                    </div>
                    <div>
                        <img src={Pago} alt="Pago" />
                        <p>Pago de impuestos y registro de la propiedad, ambos pueden ser realizados por el apoderado legal.</p>
                    </div>
                    <div>
                        <p>EN CASO DE INTERÉS EN LA GOLDEN VISA:</p>
                    </div>
                    <div>
                        <img src={Recopilación} alt="Recopilación" />
                        <p>Recopilación de documentos.</p>
                    </div>
                    <div>
                        <img src={España} alt="España" />
                        <p>Visita a España para iniciar el proceso de aplicación al permiso de residencia.</p>
                    </div>
                    <div>
                        <img src={Tarjeta} alt="Tarjeta" />
                        <p>Visita a España para recibir la tarjeta de residencia (20 días hábiles después de la aplicación).</p>
                    </div>
                </div>
                <img src={Dots} alt="Dots" className="right-top" />
                <img src={Dots} alt="Dots" className="left-middle" />
                <img src={Dots} alt="Dots" className="right-middle" />
                <img src={Dots} alt="Dots" className="left-bottom" />
            </SecondSectionMov>
        )}
        <ContactoDiv>
            <CTA title='CONTÁCTANOS' />
        </ContactoDiv>
        <LastSection>
            <div>
                <h2>GASTOS QUE DEBERÁS <span>TOMAR EN CUENTA</span></h2>
                <SeparatorLight />
            </div>
            {windowSize > 1020 ? (
                <>
                <div>
                    <CardLeft>
                        <img src={Check} alt="check" />
                        <p>Honorarios por los servicios jurídicos.</p>
                    </CardLeft>
                    <CardRight>
                        <img src={Check} alt="check" />
                        <p>Escrituración y pago de impuestos por la adquisición del inmueble.</p>
                    </CardRight>
                </div>
                <div>
                    <CardLeft>
                            <img src={Check} alt="check" />
                            <p>Registro de la propiedad.</p>
                    </CardLeft>
                    <CardRight>
                        <img src={Check} alt="check" />
                        <p>Investment fee por los servicios de Personal Shopper Inmobiliario.</p>
                    </CardRight>
                </div>
                </>
            ) : (
                <div>
                    <CardRight>
                        <img src={Check} alt="check" />
                        <p>Honorarios por los servicios jurídicos.</p>
                    </CardRight>
                    <CardRight>
                        <img src={Check} alt="check" />
                        <p>Escrituración y pago de impuestos por la adquisición del inmueble.</p>
                    </CardRight>
                    <CardRight>
                        <img src={Check} alt="check" />
                        <p>Registro de la propiedad.</p>
                    </CardRight>
                    <CardRight>
                        <img src={Check} alt="check" />
                        <p>Investment fee por los servicios de Personal Shopper Inmobiliario.</p>
                    </CardRight>
                </div>
            )}
        </LastSection>
        </Layout>
    ) : (null)
}

export default IndexPage
